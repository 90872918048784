import { Component } from '@angular/core';

@Component({
  selector: 'summary-log-creation-confirmation',
  templateUrl: './summary-log-creation-confirmation.component.html',
  styleUrls: ['./summary-log-creation-confirmation.component.css']
})
export class SummaryLogCreationConfirmationComponent {

}
